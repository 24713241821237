

.main-container {
    background-color:darkslategray;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

}
.main-container div{
    height:400px;
    width:400px;
}
.main-container  h1{
    color:antiquewhite;
    text-align: center;
    
}
.main-container img{
    width: 240px;
    height: 240px;
    margin-top:40px;
    border-radius:50%;
    object-fit: cover;
    object-position:top;
    
}
.main-container p{
    margin-left: 26px;
    margin-top: 35px;
    color: white;
    font-weight: initial;
    font-size: 22px;
  
    

}
.main-container button {
    margin-right:8px;
    margin-left: 40px;
    width: 130px;
    height:35px;
    color: darkslategray;
    margin-top:35px;
    border-radius: 4px;
}

.main-container button:hover{
    opacity:0.8;
    background: antiquewhite;
}