
.main-container {
    display: flex;
    line-height: 1.5;
}

.main-container p {
    font-size: 18px;
    color:antiquewhite;
    width:600px;
    font-family: monospace;
   
    
}
.main-container h1{
    margin-top:30px;
    font-size: 24px;
    color:antiquewhite;
    font-family: monospace;
}
.main-container ul{
   
    display: grid;
    grid-template-columns: repeat(2,1fr);
    font-size: 18px;
    color:#e8ba7d;
    width:400px;
    font-family: monospace;
    margin-left: 2rem;
}
.main-container hr{
    color:antiquewhite;
     min-width: 100px;
     height: 3px;
     background-color:#e8ba7d ;
     
     
}

@media screen and (max-width: 768px){
    .main-container p{
       font-size:14px;
       max-width:300px;
       margin-left:60px;
    }
    .main-container ul{
        max-width:300px;
        font-size:16px;
        margin-top:30px;
        margin-left:82px;
    }
    

}
