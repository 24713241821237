body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* body, html {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: %100;
  overflow-x: hidden;
} */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, hr, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  min-height: 100%;
  overflow-x: hidden;
 
 
}


.main-container {
    background-color:darkslategray;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

}
.main-container div{
    height:400px;
    width:400px;
}
.main-container  h1{
    color:antiquewhite;
    text-align: center;
    
}
.main-container img{
    width: 240px;
    height: 240px;
    margin-top:40px;
    border-radius:50%;
    object-fit: cover;
    object-position:top;
    
}
.main-container p{
    margin-left: 26px;
    margin-top: 35px;
    color: white;
    font-weight: initial;
    font-size: 22px;
  
    

}
.main-container button {
    margin-right:8px;
    margin-left: 40px;
    width: 130px;
    height:35px;
    color: darkslategray;
    margin-top:35px;
    border-radius: 4px;
}

.main-container button:hover{
    opacity:0.8;
    background: antiquewhite;
}
.resume-pdf{
    padding-top:20px;
    background-color:darkslategray;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    
}
@media screen and (max-width:768px){
    .resume-pdf{
        padding-top:50px;
        background-color:darkslategray;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        overflow-x: hidden;
        
        
    }
    .react-pdf__Page__canvas {
        margin: 0 auto;
        width: 500px!important;
        height: 100% !important;
    }
}

.main-container {
    display: flex;
    line-height: 1.5;
}

.main-container p {
    font-size: 18px;
    color:antiquewhite;
    width:600px;
    font-family: monospace;
   
    
}
.main-container h1{
    margin-top:30px;
    font-size: 24px;
    color:antiquewhite;
    font-family: monospace;
}
.main-container ul{
   
    display: grid;
    grid-template-columns: repeat(2,1fr);
    font-size: 18px;
    color:#e8ba7d;
    width:400px;
    font-family: monospace;
    margin-left: 2rem;
}
.main-container hr{
    color:antiquewhite;
     min-width: 100px;
     height: 3px;
     background-color:#e8ba7d ;
     
     
}

@media screen and (max-width: 768px){
    .main-container p{
       font-size:14px;
       max-width:300px;
       margin-left:60px;
    }
    .main-container ul{
        max-width:300px;
        font-size:16px;
        margin-top:30px;
        margin-left:82px;
    }
    

}

.header-container{
    background: #fff ;
    width: 100%;
    display: block;
    
}
.header-container-items{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height:90px;
  
}
.header-container-items h1 {
margin-left: 40px;

}
.header-container-items img{
    max-height: 90px;
    width: 70px;
    margin-left: 35px;
   
}

.nav-bar{
    display:flex;
    width:100%;
    justify-content: flex-end;
    margin-right:20px;
    
}

.nav-bar ul{
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
   
}

.nav-bar li{
    margin-right: 80px;
    text-decoration: none;
   
    letter-spacing: .5px;
   cursor: pointer;

    

}


.nav-bar a {
    text-decoration: none;
    color:darkslategray;
    
}

.nav-bar a:hover{
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
    -webkit-text-decoration-color: darkslategray;
            text-decoration-color: darkslategray;
    
}

.burger-menu {
    display: none;
    padding: 0.5rem;
    cursor: pointer;
  }
  
  .model{
      display: none;
  }

  


  @media screen and (max-width: 768px) {
*{
    margin:0;
    padding:0;
    box-sizing:border-box;
}
.bar-one,
  .bar-two,
  .bar-three{
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    background: darkslategray;
    transition: all 0.5s ease-out;
   
  }
  .bar-one,
 .bar-three {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.bar-two {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.open .bar-one {
    -webkit-transform: rotate(45deg) translate(5px,5px);
            transform: rotate(45deg) translate(5px,5px);
    
  }
    
  .open  .bar-two {
      opacity: 0;
      /* transform: translateX(20px); */
    }
  .open  .bar-three {
      -webkit-transform: rotate(-45deg) translate(7px, -6px);
              transform: rotate(-45deg) translate(7px, -6px);
    }
  

    
    .main-container{
          width:100%;
      }
    .burger-menu {
      display: block;
      cursor: pointer;
      margin-top: 24px;
      margin-right: 10px;
    
      
      
    }
    .header-container-items img{
       
        margin-left: 20px;
       
    }
    
    .nav-bar ul{
        display: none;
      
        
    }
    .model{
        
        width:100vw;
        min-height:200px;
      
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        opacity:1;
   

    }
    .model.active {
        display:flex;
        flex-direction: column;
        margin-top:90px;
        background:#c2b9b0;
        align-items: flex-start;
        
        
    }
    
    
    .model.active  li {
        text-decoration: none;
      
        font-weight: 600;
        font-size: 22px;
        padding-left:2rem;
        margin: 12px 0;
       
    }
    .model.active ul{
        display:flex;
        flex-direction: column;
        min-height:200px;
        margin-right:20px;
        font-size: 20px;
        list-style-type: none;
       
        width:100%;
       
        
       
    }
    .model.active ul li:hover {
        
        background:#907163;
        width:100%;
        color:white;
        
    }
    .model.active li a:hover {
        color:darkslategray;
    }
    .model.active  a{
        text-decoration: none;
        color: antiquewhite;
    }
   
  }
.main-container-contact {
    background-color:darkslategray;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height: 100vh;
    overflow: hidden;

}
.main-container-contact h2{
color:antiquewhite;
margin-top:40px;
text-align: center;
}

.main-container-contact h3{
    color:antiquewhite;
    margin-top:40px;
}


.main-container-contact hr{
    color:antiquewhite;
     width: 60px;
     height: 3px;
     background-color: antiquewhite;
     
}

.main-contact-form{
    height:500px;
}

.main-contact-form form{
    display: flex;
  flex-direction: column;
  height:100%;
  
    
    
}
.main-contact-form label {
color:#e8ba7d;
margin-top:30px

}

.main-contact-form input{
margin-bottom:15px;
width: 350px;
height: 30px;
border-radius: 10px;
border-color:#e8ba7d;
font-size: 14px;
padding: 8px 12px;


}
.main-contact-form input[placeholder="Enter Your Message"]{
    margin-bottom:15px;
    width: 350px;
    height: 100px;
    border-radius: 10px;
    border-color:#e8ba7d;
    font-size: 18px;
    height: 90px;

    }
    .main-contact-form input:last-child{
        width:100px;
        background-color:#e8ba7d;
        color:darkslategray;
        font-weight:bold;
        margin-top:18px;
        padding: 0px;
      

    }
    .main-contact-form input:last-child:hover{
        opacity: 0.8;
    }

    
@media screen and (max-width:768px){
    .main-container-contact {
        background-color:darkslategray;
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        height: 100vh;
        overflow: hidden;
    
    }
    .main-container-contact h2{
    color:antiquewhite;
    margin-top:40px;
    text-align: center;
    font-size:18px;
    }
    
    .main-container-contact h3{
        color:antiquewhite;
        margin-top:40px;
        font-size:16px;
    }
    
    
    .main-container-contact hr{
        color:antiquewhite;
         width: 60px;
         height: 3px;
         background-color: antiquewhite;
         margin-left:134px;
         
    }
    
    .main-contact-form{
        height:500px;
    }
    
    .main-contact-form form{
        display: flex;
      flex-direction: column;
      
        
        
    }
    .main-contact-form label {
    color:#e8ba7d;
    margin-top:30px
    
    }
    
    .main-contact-form input{
    margin-bottom:15px;
    width: 300px;
    height: 30px;
    border-radius: 10px;
    border-color:#e8ba7d;
    font-size: 14px;
    padding: 8px 12px;
    
    
    }
    .main-contact-form input[placeholder="Enter Your Message"]{
        margin-bottom:15px;
        width: 300px;
        height: 100px;
        border-radius: 10px;
        border-color:#e8ba7d;
        font-size: 18px;
        height: 90px;
    
        }
        .main-contact-form input:last-child{
            width:100px;
            background-color:#e8ba7d;
            color:darkslategray;
            font-weight:bold;
            margin-top:18px;
            padding: 0px;
          
    
        }
        .main-contact-form input:last-child:hover{
            opacity: 0.8;
        }
    
}
   
